:root {
  /* grays */
  --gray-900: #1d1d1d;
  --gray-700: #3f3f3f;
  --gray-500: #5d5d5d;
  --gray-300: #7e7e7e;
  --gray-100: #999999;

  /* blues */
  --blue-900: #425055;
  --blue-700: #586b71;
  --blue-500: #6e868d;
  --blue-300: #7d9298;
  --blue-100: #9aaaaf;

  --light-blue-darken-1: #039be5;
  --light-blue-darken-4: #01579b;

  --blue: #3bbce6;

  /* amber */
  --amber-darken-3: #ff8f00;

  /* reds */
  --red-900: #4f3737;
  --red-700: #694a4a;
  --red-500: #835c5c;
  --red-300: #9c7d7d;
  --red-100: #b59d9d;

  /* greens */
  --green-900: #3a413a;
  --green-700: #4d564e;
  --green-500: #606c61;
  --green-300: #808981;
  --green-100: #c7cac7;
  --green-darken-3: #2e7d32;

  -green: #00d07d;

  --white: #fff;

  /* fonts */
  --logo-font: 'Pacifico', cursive;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: #0f111a;
  background: #fff;
  font-family: 'Lucida Sans Typewriter', 'Lucida Console', Monaco,
    'Bitstream Vera Sans Mono', monospace;
}

h1 {
  font-family: 'Lucida Sans Typewriter', 'Lucida Console', Monaco,
    'Bitstream Vera Sans Mono', monospace;
  text-transform: none;
}

.zero-padding .mat-dialog-container {
  padding: 0px;
}

.highlight {
  background: #fff899;
}

.lg-font {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: bold;
}

.md-font {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
}

.sm-font {
  font-size: 1rem;
}

.line {
  left: auto;
  width: 60px;
  right: 0px;
  height: 5px;
  background: #484644;
  transition: 1.5s all cubic-bezier(0.23, 1, 0.32, 1);
}

.page-header {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.page-header h3 {
  font-size: 3em;
  margin-bottom: 0px;
  font-weight: bold;
  width: 40%;
  min-width: 250px;
}

.page-header .page-number {
  font-size: 0.75em;
}

.line {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 10%;
  top: -200px;
  z-index: -1;
  height: 850px;
  width: 4px;
  background: #f6f6f6;
}

.line-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -100px;
}

.sub-header {
  font-size: 1.5em;
  font-weight: bold;
  width: 350px;
}

.sidenav-dialog .mat-dialog-container {
  padding: 0px;
}

.resume {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid var(--light-blue-darken-4);
}

@media only screen and (max-width: 767px) {
  .page-header h3 {
    font-size: 2em;
    width: 300px;
  }

  .lg-font {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: bold;
  }

  .md-font {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
  }

  .sm-font {
    font-size: 1rem;
  }

  .page-header .page-number {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .page-header h3 {
    font-size: 2em;
    width: 400px;
  }
}
